const ChipsTitle: { [x: string]: string } = {
  active: "Aktif",
  inactive: "Tidak Aktif"
};

const ChipsColor = (status: string) => {
  // for multiple status, use ^ before status, ex: ^active|^success
  if (status.match(/^active|green/gi)) return "green";
  if (status.match(/^waiting|yellow/gi)) return "yellow";
  if (status.match(/^inactive|red/gi)) return "red";
  return "gray";
};

interface Chips {
  status: string;
  title?: string;
  square?: boolean;
}
export const ChipsStyles = (props: Chips) => {
  let style = "";

  switch (ChipsColor(props.status)) {
    case "green":
      style = "bg-green-lp-300 text-green-lp-200";
      break;

    case "yellow":
      style = "bg-yellow-lp-200 text-yellow-lp-100";
      break;

    case "red":
      style = "bg-red-300 text-red-lp-200";
      break;

    case "gray":
      style = "bg-gray-lp-200 text-black-lp-300";
      break;

    default:
      break;
  }

  const shape = props.square ? "rounded" : "rounded-full";
  return props.status && style
    ? `<div class="flex"><div class="${shape} px-2 py-0 ${style}"><span class="capitalize">${props.title ||
        ChipsTitle[props.status.toLowerCase()]}</span></div></div>`
    : "-";
};
